<template>  
    <v-row class="ma-0 pa-0 px-6 producer-content-container" justify="space-between" align="center">
      <v-col cols="12" class="col-lg-6 justify-lg-start justify-center d-flex pa-0 ">
        <div class="mr-5 align-center d-flex justify-center pa-2 px-5 text-h6 text-uppercase primary--text font-weight-bold
          border-w-3 border-solid border-primary">
            <v-icon color="primary" class="mr-2" dark>mdi-clock-outline</v-icon>          
            <div style="width:130px" class="justify-center d-flex">
              {{ currentTime }}
            </div>
        </div>
        <div v-if="!producerActcampaignLoading" class="mr-5 text-h6 pa-2 px-5 text-capitalize primary--text font-weight-bold
          border-w-3 border-solid border-primary">
          {{ producerActCampModel ? producerActCampModel.progress : 0 }}% Raised
        </div>
        <div v-if="!prodGetOptLoading" class="mr-5 text-h6 pa-2 px-5 text-capitalize primary--text font-weight-bold
          border-w-3 border-solid border-primary">
          Phone {{ model.ACTIVE_PHONE }}/{{ model.NO_OF_PHONE }}<span class="mx-4">|</span>Web {{ model.NO_OF_WEB }}
        </div>
        <template v-if="prodGetOptLoading">
          <v-skeleton-loader v-for="(item, i) in [1,2]" :key="i"
              v-bind="{class: 'header-view-skeleton mr-5'}"
              type="button"
          ></v-skeleton-loader>
       </template>
      </v-col>
      <v-col cols="12" class="col-lg-6 justify-lg-end align-center justify-center d-flex pa-0 py-2 py-lg-0">
            <div class="skeleton-list-item w-full"  v-if="userDropdown && adminUserLoading">
                <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
            </div>
            <v-autocomplete :disabled="userEditLoading" class="autocomplete-append-btn rounded-r-0" v-if="userDropdown && !adminUserLoading" v-model="model.TARGET_EDITOR" background-color="white" outlined
                hide-details :items="adminUserLists" placeholder="Select User" attach
                :menu-props="{ closeOnClick: true, closeOnContentClick: true}" return-object>
                <template v-slot:append-outer>
                    <v-btn :loading="userEditLoading" class="rounded-l-0" color="info" @click="userDropdown && saveTargetEditor()">
                        <v-icon >mdi-content-save-settings</v-icon>
                    </v-btn>
                </template>
            </v-autocomplete>
           <v-btn v-if="!userDropdown && $helpers.getCurData('curUserPermissions').indexOf('set_target_editor') !== -1" text class="text-capitalize text-subtitle-2 target-button"
               color="info" @click="userDropdown = !userDropdown">
               <v-icon dark class="mb-1" v-if="!userDropdown" large color="info">mdi-pencil</v-icon>
               <v-icon dark class="mb-1" v-else large color="info">mdi-pencil</v-icon>
               Target Editor
           </v-btn>
          <v-btn text class="text-capitalize text-subtitle-2 target-button" color="info"
            v-if="$helpers.getCurData('curUserPermissions').indexOf('donation') !== -1"
            to="/last-3hours-summary">
              <v-icon dark class="mb-1" large color="info">mdi-chart-box-outline</v-icon>
              Hourly Report
          </v-btn>
          <v-btn text class="text-capitalize text-subtitle-2 target-button" color="info"
            @click="openAirMsgModal = true;">
              <v-icon dark class="mb-1" large color="info">mdi-text-box</v-icon>
              On Air message
          </v-btn>
          <v-btn v-if="!prodGetOptLoading" text class="text-capitalize text-subtitle-2 target-button" color="info" @click="!model.SHOW_OFF_AIRLoading && toggleSwitch('SHOW_OFF_AIR')">
              <v-switch readonly :loading="model.SHOW_OFF_AIRLoading" v-model="model.SHOW_OFF_AIR" :false-value="0" :true-value="1" dense label="" color="info" hide-details class="ma-0 pa-0 mb-4"></v-switch>
              Include Off Air
          </v-btn>
          <v-btn v-if="!prodGetOptLoading" text class="text-capitalize text-subtitle-2 target-button" color="info" @click="!model.SHOW_TALLY_REMAININGLoading && toggleSwitch('SHOW_TALLY_REMAINING')">
              <v-switch readonly :loading="model.SHOW_TALLY_REMAININGLoading" v-model="model.SHOW_TALLY_REMAINING" :false-value="0" :true-value="1" dense label="" color="info" hide-details class="ma-0 pa-0 mb-4"></v-switch>
              Public Tally $
          </v-btn>
          <v-btn v-if="!prodGetOptLoading" text class="text-capitalize text-subtitle-2 target-button" color="info" @click="!model.SHOW_INCENTIVELoading && toggleSwitch('SHOW_INCENTIVE')">
             <v-switch readonly dense :loading="model.SHOW_INCENTIVELoading" v-model="model.SHOW_INCENTIVE" :false-value="0" :true-value="1" label="" color="info" hide-details class="ma-0 pa-0 mb-4"></v-switch>
              Incentive
          </v-btn>
          <v-btn v-if="!prodGetOptLoading" text class="text-capitalize text-subtitle-2 target-button" color="info" @click="!model.SHOW_CHALLENGELoading && toggleSwitch('SHOW_CHALLENGE')">
              <v-switch readonly :loading="model.SHOW_CHALLENGELoading" v-model="model.SHOW_CHALLENGE" :false-value="0" :true-value="1" dense label="" color="info" hide-details class="ma-0 pa-0 mb-4"></v-switch>
              Challenge
          </v-btn>
          <template v-if="prodGetOptLoading">
            <v-skeleton-loader v-for="(item, i) in [1,2]" :key="i"
                v-bind="{class: 'header-action-skeleton mr-5'}"
                type="button"
            ></v-skeleton-loader>
        </template>
      </v-col>
      <OnAirMessageModal v-if="openAirMsgModal" :openAirMsgModal="openAirMsgModal"  @closeModal="openAirMsgModal = false"/>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    emits: ['targetEditorUpdate'],
    components: {
        OnAirMessageModal: () => import("@/pages/active-campaign/producer/components/header-action-component/OnAirMessage")
    },
    data() {
        return {
            model : {
                SHOW_INCENTIVE: 0,
                SHOW_CHALLENGE: 0,
                SHOW_OFF_AIR: 0,
                SHOW_TALLY_REMAINING: 0,
                NO_OF_PHONE: 0,
                NO_OF_WEB: 0,
                ACTIVE_PHONE: 0,
                SHOW_INCENTIVELoading: false,
                SHOW_CHALLENGELoading: false,
                TARGET_EDITOR: null
            },
            timerId: null,
            currentTime: '',
            openAirMsgModal: false,
            userDropdown: false,
            userEditLoading: false,
        }
    },
    computed: {
        ...mapGetters(['producerActcampaignLoading', 'prodGetOptLoading', 'producerActCampModel',
            'adminUserLists', 'adminUserLoading']),

    },
    created() {
        this.getActiveCampaign({with_variation: 1});
        const reqData = {
            name: 'search',
            value: {
                option_name: ['SHOW_INCENTIVE', 'SHOW_CHALLENGE', 'TARGET_EDITOR', 'SHOW_OFF_AIR','SHOW_TALLY_REMAINING'],
            }
        }
        this.getOptionsData(reqData).then((response) => {
            this.model = {...this.model, ...response.data.data};
            this.$emit('targetEditorUpdate', this.model.TARGET_EDITOR);
        });
        this.getEmailOptInfo();
        this.wsOnActionUpdate();
        this.getAdminUserLists();
        this.getMsgData();
        this.toggleSwitch('SHOW_OFF_AIR');
        this.timerId = setInterval(() => {
            this.getLiveTime();
        }, 1000);
    },
    methods: {
        ...mapActions(['updatesOptionsData', 'getActiveCampaign', 'getOptionsData', 'getEmailOptionsData',
            'getAdminUserLists']),

        getEmailOptInfo () {
             const reqData = {
                name: 'search',
                value: {
                    option_name: ['NO_OF_PHONE', 'NO_OF_WEB', 'ACTIVE_PHONE'],
                }
            }
            this.getEmailOptionsData(reqData).then((response) => {
                this.model = {...this.model, ...response.data.data};
            });
        },
        toggleSwitch(name) {
            this.model[`${name}Loading`] = true;
            this.model[name] = this.model[name] === 1 ? 0 : 1;
            const reqData = {
                value: this.model[name],
                name: name
            }
            this.updatesOptionsData(reqData).then((resp) => {
                this.model[`${name}Loading`] = false;
            }).catch(() => this.model[`${name}Loading`] = false);
        },
        getLiveTime() {
            this.currentTime = this.$helpers.getMomentDatas('hh:mm:ss A');
        },
        getMsgData() {
            const reqData = {
                name: 'search',
                value: {
                    option_name: ['ON_AIR_SHORT_MESSAGE', 'ON_AIR_LONG_MESSAGE'],
                }
            }
            this.getOptionsData(reqData).then((response) => {
                const resp = response.data.data || null;
                if (resp) {
                    this.$store.commit('setOnAirMsgModel', resp)
                }
            });
        },
        saveTargetEditor() {
            if (this.model.TARGET_EDITOR) {
                this.userEditLoading = true;
                const reqData = {
                    value: this.model.TARGET_EDITOR,
                    name: 'TARGET_EDITOR'
                }
                this.updatesOptionsData(reqData).then((response) => {
                    if (!response) {
                        return;
                    }
                    this.$emit('targetEditorUpdate', this.model.TARGET_EDITOR);
                    this.$store.commit("snackbar/SHOW_MESSAGE", {
                        text: "Target Editor Updated Successfully",
                        color: "success",
                    });
                    this.userEditLoading = this.userDropdown = false;
                }).catch(() => this.userEditLoading = false);
            }
        },
        wsOnActionUpdate() {
            Echo.channel('SHOW_CHALLENGE').listen('.saved',(challenge) => {
                this.model.SHOW_CHALLENGE = challenge.data;
            });
            Echo.channel('SHOW_OFF_AIR').listen('.saved',(challenge) => {
                this.model.SHOW_OFF_AIR = challenge.data;
            });
            Echo.channel('SHOW_TALLY_REMAINING').listen('.saved',(challenge) => {
                this.model.SHOW_TALLY_REMAINING = challenge.data;
            });
            Echo.channel('SHOW_INCENTIVE').listen('.saved',(incentive) => {
                this.model.SHOW_INCENTIVE = incentive.data;
            });
            Echo.channel('NO_OF_PHONE').listen('.saved',(noOfPh) => {
                this.model.NO_OF_PHONE = noOfPh.data;
            });
            Echo.channel('NO_OF_WEB').listen('.saved',(noOfWeb) => {
                this.model.NO_OF_WEB = noOfWeb.data;
            });
            Echo.channel('ACTIVE_PHONE').listen('.saved',(activePhone) => {
                this.model.ACTIVE_PHONE = activePhone.data;
            });
            Echo.channel('TARGET_EDITOR').listen('.saved',(targetEditor) => {
                const targetValue = targetEditor.data || null;
                if (targetValue && this.model.TARGET_EDITOR.value != targetValue.value) {
                    this.model.TARGET_EDITOR = targetValue;
                    this.$emit('targetEditorUpdate', this.model.TARGET_EDITOR);
                }
            });
        },
    },
    beforeDestroy() {
        Echo.leaveChannel('SHOW_CHALLENGE');
        Echo.leaveChannel('SHOW_OFF_AIR');
        Echo.leaveChannel('SHOW_TALLY_REMAINING');
        Echo.leaveChannel('SHOW_INCENTIVE');
        Echo.leaveChannel('NO_OF_PHONE');
        Echo.leaveChannel('NO_OF_WEB');
        Echo.leaveChannel('ACTIVE_PHONE');
        Echo.leaveChannel('TARGET_EDITOR');
        if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
        }
    }
}
</script>
